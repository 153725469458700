import React from "react"
import PropTypes from "prop-types"
import LifeLine from 'lifeline-react';

const LifelineTrigger = (props) => {
  return (
    <div id='lifeline'>
      <LifeLine />
    </div>
  )
}


export default LifelineTrigger
